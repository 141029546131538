export const FETCH_USER = 'fetch_user';
export const FETCH_SURVEYS = 'fetch_surveys';
// export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_MENU_CATEGORIES = 'FETCH_MENU_CATEGORIES';
export const GET_MENU_ITEMS_BY_CATEGORY_ID = 'get_menu_items_by_category_id';
export const FETCH_MENU_ITEM_BY_ID = 'fetch_menu_item_by_id';
export const FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID = 'fetch_menu_item_combos_by_menu_item_id';
export const FETCH_MULTIPLE_MENU_ITEM_BY_ID = 'fetch_multiple_menu_item_by_id';
export const FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID = 'fetch_multiple_menu_item_ingredients_by_id';
export const FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS = 'fetch_menu_item_by_id_with_ingredients';
export const CLEAR_MENU_ITEMS = 'clear_menu_items';
export const FETCH_MENU_ITEMS = 'fetch_menu_items';
export const FETCH_INGREDIENTS = 'fetch_ingredients';
export const FETCH_INGREDIENT_MENU_ITEMS = 'fetch_ingredient_menu_items';
export const CLEAR_MENU_CATEGORY_MENU_ITEMS = 'clear_menu_category_menu_items';
export const CLEAR_MENU_ITEM_COMBO_ITEMS = 'clear_menu_item_combo_items';
export const CLEAR_MENU_ITEM_INGREDIENTS = 'clear_menu_item_ingredients';

export const IS_RESTURANT_OPEN = 'IS_RESTURANT_OPEN';
export const FETCH_RESTURANT_TAX = 'fetch_resturant_tax';
export const FETCH_RESTAURANT_PROVINCE = 'fetch_restaurant_province';
export const FETCH_RESTAURANT_NAME = 'fetch_restaurant_name';
export const FETCH_POS_COMBO_ITEM_COLOR = 'fetch_pos_combo_item_color';
export const FETCH_RESTURANT_DELIVERY_FEES = 'fetch_resturant_delivery_fees';
export const FETCH_RESTURANT_ADDRESS = 'fetch_resturant_address';
export const FETCH_RESTAURANT_LOGO = 'fetch_restaurant_logo';
export const FETCH_RESTAURANT_CARD_PROCESSING_FEE = 'fetch_restaurant_card_processing_fee';
// Order Types
export const SAVE_ORDER = 'save_order';
export const EDIT_ORDER = 'edit_order';
export const FETCH_EXISTING_ORDERS = 'fetch_existing_orders';
export const FETCH_FOOD_APPS_EXISTING_ORDERS = 'fetch_food_apps_existing_orders';
export const DELETE_ALL_EXISTING_FOOD_APPS_ORDERS = 'delete_all_existing_food_apps_orders';
export const CLEAR_EXISTING_FOOD_APPS_ORDERS_HISTORY = 'clear_existing_food_apps_orders_history';
export const FETCH_WEB_EXISTING_ORDERS = 'fetch_web_existing_orders';
export const FETCH_POS_TODAY_ORDERS = 'fetch_pos_today_orders';
export const DELETE_POS_ORDER_FROM_POS_ORDERS_PAGE = 'delete_pos_order_from_pos_orders_page';
export const FETCH_TODAY_EXISTING_ORDERS = 'fetch_today_existing_orders';
export const FETCH_TODAY_MISTAKE_EXISTING_ORDERS = 'fetch_today_mistake_existing_orders';
export const FETCH_ALL_POS_KITCHEN_ORDERS = 'fetch_all_pos_kitchen_orders';
export const DELETE_POS_ORDER_FROM_KITCHEN_DISPLAY = 'delete_pos_order_from_kitchen_display';
export const FETCH_ORDER_BY_ID = 'fetch_order_by_id';
export const DELETE_ORDER_BY_ID = 'delete_order_by_id';
export const CLEAR_ORDER_HISTORY = 'clear_order_history';
export const CLEAR_EXISTING_ORDERS_HISTORY = 'clear_existing_orders_history';
export const CLEAR_WEB_EXISTING_ORDERS_HISTORY = 'clear_web_existing_orders_history';
export const FETCH_MAX_ORDER_TICKET_NUMBER = 'fetch_max_order_ticket_number';
export const FETCH_POS_ORDERS = 'fetch_pos_orders';
export const FETCH_EXISTING_DELIVERY_ORDERS = 'fetch_existing_delivery_orders';
export const CLEAR_POS_ORDERS_HISTORY = 'clear_pos_orders_history';
export const FETCH_MISTAKE_EXISTING_ORDERS = 'fetch_mistake_existing_orders';
export const CLEAR_MISTAKE_EXISTING_ORDERS_HISTORY = 'clear_mistake_existing_orders_history';
export const FETCH_POS_KITCHEN_ORDERS = 'fetch_pos_kitchen_orders';
export const CLEAR_POS_KITCHEN_ORDERS_HISTORY = 'clear_pos_kitchen_orders_history';
export const CHANGE_POS_KITCHEN_ORDER_STATUS = 'change_pos_kitchen_order_status';
export const ADD_NEW_POS_ORDER_TO_KITCHEN_DISPLAY = 'add_new_pos_order_to_kitchen_display';
export const UPDATE_POS_ORDER_TO_KITCHEN_DISPLAY = 'update_pos_order_to_kitchen_display';
export const FETCH_WEB_ORDER_NOTIFICATIONS_COUNT = 'fetch_web_order_notifications_count';
export const FETCH_TABLET_ORDER_NOTIFICATIONS_COUNT = 'fetch_tablet_order_notifications_count';
// Other Types
export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const ADD_TO_CART_USER = 'add_to_cart_user';
export const GET_CART_ITEMS_USER = 'get_cart_item_user';
export const REMOVE_CART_ITEM_USER = 'remove_cart_item_user';
export const REMOVE_INGREDIENT_FROM_MENU_ITEM_CART = 'remove_ingredient_from_menu_item_cart';
export const REMOVE_INGREDIENT_FROM_COMBO_ITEM_CART = 'remove_ingredient_from_combo_item_cart';
export const REMOVE_CART_SUB_ITEM_USER = 'remove_cart_sub_item_user';
export const ON_SUCCESS_BUY_USER = 'on_success_buy_user';
export const UPDATE_DATA_USER = 'update_data_user';
export const CLEAR_UPDATE_USER_DATA = 'clear_update_user_data'
export const CLEAR_CART = 'clear_cart';
export const ADD_INGREDIENT_TO_MENU_ITEM_CART = 'add_ingredient_to_menu_item_cart';
export const ADD_COMBO_ITEM_TO_MENU_ITEM = 'add_combo_item_to_menu_item';
export const ADD_INGREDIENT_TO_MENU_ITEM_COMBO_ITEM_CART = 'add_ingredient_to_menu_item_combo_item_cart';
export const ADD_NOTES_WITH_EXTRA_CHARGE = 'add_notes_with_extra_charge';
export const ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM = 'add_notes_with_extra_charge_to_cart_menu_item';
export const ADD_NOTES_WITH_EXTRA_CHARGE_TO_CART_MENU_ITEM_COMBO_ITEM = 'add_notes_with_extra_charge_to_cart_menu_item_combo_item';
export const REMOVE_CART_ITEM_NOTES = 'remove_cart_item_notes';
export const ADD_REFUNDED_ITEM_TO_CART = 'add_refunded_item_to_cart';
export const FILL_CART_WITH_EXISTING_ORDER = 'fill_cart_with_existing_order';
export const ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_MENU_ITEM = 'add_included_and_both_ingredients_to_menu_item';
export const ADD_INCLUDED_AND_BOTH_INGREDIENTS_TO_COMBO_MENU_ITEM = 'add_included_and_both_ingredients_to_combo_menu_item';

export const GET_PRODUCTS_BY_SELL = 'get_products_by_sell';
export const GET_PRODUCTS_BY_ARRIVAL = 'get_products_by_arrival';
export const GET_BRANDS = 'get_brands';
export const ADD_BRAND = 'add_brand';
export const GET_WOODS =  'get_woods';
export const ADD_WOOD = 'add_wood';
export const GET_PRODUCTS_TO_SHOP = 'get_products_to_shop';
export const ADD_PRODUCT = 'add_product';
export const CLEAR_PRODUCT = 'clear_product';
export const GET_PRODUCT_DETAIL = 'get_product_detail';
export const CLEAR_PRODUCT_DETAIL = 'clear_product_detail';

export const GET_SITE_DATA = 'get_site_data';
export const UPDATE_SITE_DATA = 'update_site_data';

// Tablet Types
export const FETCH_NEW_TABLET_NOTIFICATION_ORDERS = 'fetch_new_tablet_notification_orders';
export const FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS = 'fetch_existing_tablet_notification_orders';
export const CLEAR_NEW_TABLET_NOTIFICATION_ORDERS_HISTORY = 'clear_new_tablet_notification_orders_history';
export const CLEAR_EXISTING_TABLET_NOTIFICATION_ORDERS_HISTORY = 'clear_existing_tablet_notification_orders_history';