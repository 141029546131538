import React, { Fragment } from 'react';
import LoadingSpinner from '../../../../../utils/LoadingSpinner';
import LoadMoreFoodAppsExistingOrderItems from './LoadMoreFoodAppsExistingOrderItems';
const FoodAppsExistingOrdersModalBody = (props) => {
  const order = props.order;
  /* console.log('order.total_food_apps_existing_orders')
  console.log('order.total_food_apps_existing_orders')
  console.log('order.total_food_apps_existing_orders')
  console.log(order.total_food_apps_existing_orders) */
  if (Object.keys(order).length === 0 || order.existingFoodAppsOrders === undefined) {
    return (
      <LoadingSpinner subClass={'mt10 mb10 text-center'} />
    )
  } else {

  }
  return (
    <Fragment>
      <div className="row">
        {/* Delete Button Below Table */}
        <div className="col-12 float-right text-right mt-3 mb-3">
          <button className="btn btn-danger" 
            onClick={() => {props.btnOnClickDeleteAllExistingFoodAppOrders()}}
          >
            Delete Orders
          </button>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Delivery Type</th>
                <th>Name</th>
                <th>Time</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                order.existingFoodAppsOrders !== 'undefined' && order.existingFoodAppsOrders !== undefined ?
                  <LoadMoreFoodAppsExistingOrderItems
                    limit={props.limitExstingOrders}
                    total_food_apps_existing_orders={order.total_food_apps_existing_orders}
                    Orders={order.existingFoodAppsOrders}
                    onClickHandleLoadMoreExistingFoodAppOrderItems={props.onClickHandleLoadMoreExistingFoodAppOrderItems}
                    handelOnClickExistingFoodAppsOrder={props.handelOnClickExistingFoodAppsOrder}
                  />
                  : null
              }
            </tbody>
          </table>

          {
            props.isloadingExistingFoodAppsOrders ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> : null
          }
        </div>
      </div>
    </Fragment>
  );
}

export default FoodAppsExistingOrdersModalBody;