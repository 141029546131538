import React, { Component, Fragment } from 'react';
// import MyButton from './button';

import { connect } from 'react-redux';
import OrderTypes from './OrderTypes';
import Notifications from './Notifications/Notifications';
import MenuCategories from './MenuCategories/MenuCategories';
// import NotePad from './NotePad/NotePad';
import seventhPng from '../../../../common/images/icons/7.png'
import SauceIngredients from './IngredientsType/SauceIngredients';
import MeatCheeseIngredients from './IngredientsType/MeatCheeseIngredients';
import MiscIngredients from './IngredientsType/MiscIngredients';
import MenuItemComboItems from './MenuItem/MenuItemComboItems';
import MenuItemsColumn from './MenuItem/MenuItemsColumn';
import MenuItemIngredientsTypes from './IngredientsType/MenuItemIngredientsTypes';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
// import { Link } from 'react-router-dom';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import IsNewOrExistingCustomerSelectionModal from './IsNewOrExistingCustomerSelectionModal/isNewOrExistingCustomerSelectionModal';
import ExistingOrdersModal from './ExistingOrdersModal/ExistingOrdersModal';
import WebOrdersNotificationModal from './WebOrdersNotificationModal/WebOrdersNotificationModal';
import { Online } from "react-detect-offline";
import IsNewOrExistingTabletOrderNotificationSelectionModal from './IsNewOrExistingTabletOrderNotificationSelectionModal/IsNewOrExistingTabletOrderNotificationSelectionModal';
import TabletNewOrdersNotificationModal from './TabletNewOrdersNotificationModal/TabletNewOrdersNotificationModal';
import TabletExistingOrdersNotificationModal from './TabletExistingOrdersNotificationModal/TabletExistingOrdersNotificationModal';
import iconKioskPng from '../../images/notifications-images/icon-kiosk.png'
// import iconMobilePng from '../../images/notifications-images/icon-mobile.png'
import MenuItemSizes from './MenuItem/MenuItemSizes';
import MenuItemSpecialModifiers from './MenuItem/MenuItemSpecialModifiers/MenuItemSpecialModifiers';
import FoodAppsCustomerInformationModal from './FoodAppsCustomerInformationModal/FoodAppsCustomerInformationModal';
import FoodAppsExistingsOrderMenuOptionsModal from './FoodAppsExistingsOrderMenuOptionsModal/FoodAppsExistingsOrderMenuOptionsModal';
import FoodAppsExistingOrdersModal from './FoodAppsExistingOrdersModal/FoodAppsExistingOrdersModal';
// Reducers
// import { addToCart } from '../../../../../actions/cart_actions';
/* import { fetchMenuCatgories,getMenuItemsByCategoryId,clearMenuCategoryMenuItems } from '../../../../../actions/menu_category_actions';addToCart
import {
    fetchMenuItemCombosByMenuItemId,
    fetchMenuItemByIdWithIngredients,
    clearMenuItemComboItems,
    clearMenuItemIngredients
} from '../../../../../actions/menu_item_actions'; */
class FoodMenu extends Component {
    
    state = {
        name_key:'',
        
    };
    changeKeyname = () => {
        console.log('i am changing the key name')
        console.log('i am changing the key name')
        console.log('i am changing the key name')
        console.log('i am changing the key name')
        this.setState({ name_key: Math.random() });
    }
    render() {
        const menuCategories = this.props.menuCategories; 
        const MenuItems = this.props.MenuItems; 
        const total_menu_items = this.props.total_menu_items; 
        const MenuItemCombos = this.props.MenuItemCombos; 
        const total_menu_item_combos = this.props.total_menu_item_combos; 
        const menuItemWithIngredient = this.props.menuItemWithIngredient; 
        const total_sauce_ingredients = this.props.total_sauce_ingredients; 
        const total_meat_cheese_ingredients = this.props.total_meat_cheese_ingredients; 
        const total_misc_ingredients = this.props.total_misc_ingredients; 
        // console.log('this.props.menuCategoryId in foodmeny')
        // console.log(this.props.menuCategoryId)
        return (
            // <!-- food-menus -->
            <div className="food-menus text-uppercase">
                {/* <!-- food-menus-head --> */}
                <div className="food-menus-head">
                    <div className="row">
                        <div className="col-8">
                            <OrderTypes 
                                selectedOrderType={this.props.selectedOrderType}
                                // selectedOrderType={this.state.selectedOrderType}
                                handleOnClickOrderType={this.props.handleOnClickOrderType}
                            />
                            <CustomerDetails 
                                showCustomerInformationModal={this.props.showCustomerInformationModal}
                                handleOnChangeCustomerModalToggle={this.props.handleOnChangeCustomerModalToggle}
                                handleOnChangeCustomerData={this.props.handleOnChangeCustomerData}
                                handleOnChangeCustomerDetailsState={this.props.handleOnChangeCustomerDetailsState}
                                origin={this.props.origin}
                                city={this.props.city}
                                query={this.props.query}
                                delivery_type={this.props.delivery_type}
                                isCustomerSavingLoader={this.props.isCustomerSavingLoader}
                                customerFormData ={this.props.customerFormData}
                                selectedPhoneNumber={this.props.selectedPhoneNumber}
                                tax ={this.props.tax}
                                delivery_amount={this.props.delivery_amount}
                                distance_in_kilo={this.props.distance_in_kilo}
                                time_picker_time={this.props.time_picker_time}
                                value={this.props.value}
                                suggestions={this.props.suggestions}
                                phone_no={this.props.phone_no}
                                isMistakeActive={this.props.isMistakeActive}
                                mistake_type={this.props.mistake_type}
                                selectedOrderType={this.props.selectedOrderType}
                                changeKeyname={this.changeKeyname}
                                name_key={this.state.name_key}
                                existing_order_id={this.props.existing_order_id}
                                handleOnChangeDrfDefaultTime={this.props.handleOnChangeDrfDefaultTime}
                                handleOnChangetimeSelectionRadioBox={this.props.handleOnChangetimeSelectionRadioBox}
                                drp_default_time={this.props.drp_default_time}
                                timeSelectionRadioBox={this.props.timeSelectionRadioBox}
                                deliveryFees={this.props.deliveryFees}
                                card_processing_fee={this.props.card_processing_fee}
                                handleOnChangeDeliveryPaymentMethod={this.props.handleOnChangeDeliveryPaymentMethod}
                                selectedDeliveryPaymentMethod={this.props.selectedDeliveryPaymentMethod}
                                
                            />
                            <FoodAppsCustomerInformationModal 
                                showFoodAppsCustomerInformationModal={this.props.showFoodAppsCustomerInformationModal}
                                handleOnChangeCustomerModalToggle={this.props.handleOnChangeCustomerModalToggle}
                                handleOnChangeFoodAppsCustomerModalToggle={this.props.handleOnChangeFoodAppsCustomerModalToggle}
                                handleOnChangeCustomerData={this.props.handleOnChangeCustomerData}
                                handleOnChangeCustomerDetailsState={this.props.handleOnChangeCustomerDetailsState}
                                origin={this.props.origin}
                                city={this.props.city}
                                query={this.props.query}
                                delivery_type={this.props.delivery_type}
                                isCustomerSavingLoader={this.props.isCustomerSavingLoader}
                                customerFormData ={this.props.customerFormData}
                                selectedPhoneNumber={this.props.selectedPhoneNumber}
                                tax ={this.props.tax}
                                delivery_amount={this.props.delivery_amount}
                                distance_in_kilo={this.props.distance_in_kilo}
                                time_picker_time={this.props.time_picker_time}
                                value={this.props.value}
                                suggestions={this.props.suggestions}
                                phone_no={this.props.phone_no}
                                isMistakeActive={this.props.isMistakeActive}
                                mistake_type={this.props.mistake_type}
                                selectedOrderType={this.props.selectedOrderType}
                                changeKeyname={this.changeKeyname}
                                name_key={this.state.name_key}
                                existing_order_id={this.props.existing_order_id}
                                handleOnChangeDrfDefaultTime={this.props.handleOnChangeDrfDefaultTime}
                                handleOnChangetimeSelectionRadioBox={this.props.handleOnChangetimeSelectionRadioBox}
                                drp_default_time={this.props.drp_default_time}
                                timeSelectionRadioBox={this.props.timeSelectionRadioBox}
                                deliveryFees={this.props.deliveryFees}
                                card_processing_fee={this.props.card_processing_fee}
                                handleOnChangeDeliveryPaymentMethod={this.props.handleOnChangeDeliveryPaymentMethod}
                                selectedDeliveryPaymentMethod={this.props.selectedDeliveryPaymentMethod}
                            />
                            <IsNewOrExistingCustomerSelectionModal 
                                selectedOrderType={this.props.selectedOrderType}
                                showIsNewExistingCustomerSelectionModal={this.props.showIsNewExistingCustomerSelectionModal}
                                // showIsNewExistingCustomerSelectionModal={this.state.showIsNewExistingCustomerSelectionModal}
                                handleOnChangeNewOrExistingCustomerModalToggle={this.props.handleOnChangeNewOrExistingCustomerModalToggle}
                                handleOnClickCustomerTypeSelection={this.props.handleOnClickCustomerTypeSelection}
                            />
                            <ExistingOrdersModal 
                                showExistingOrdersModal={this.props.showExistingOrdersModal}
                                order={this.props.order}
                                onClickHandleLoadMoreExistingOrderItems={this.props.onClickHandleLoadMoreExistingOrderItems}
                                clearExistingOrderLimits={this.props.clearExistingOrderLimits}
                                limitExstingOrders={this.props.limitExstingOrders}
                                isloadingExistingOrders={this.props.isloadingExistingOrders}
                                // showExistingOrdersModal={this.state.showExistingOrdersModal}
                                handleOnChangeExistingOrderModalToggle={this.props.handleOnChangeExistingOrderModalToggle}
                                handelOnClickExistingOrder={this.props.handelOnClickExistingOrder}
                                clearExistingOrderHistory={this.props.clearExistingOrderHistory}
                            />
                            <WebOrdersNotificationModal 
                                showWebOrdersNotificationModal={this.props.showWebOrdersNotificationModal}
                                order={this.props.order}
                                onClickHandleLoadMoreWebExistingOrderItems={this.props.onClickHandleLoadMoreWebExistingOrderItems}
                                clearWebExistingOrderLimits={this.props.clearWebExistingOrderLimits}
                                limitWebExstingOrders={this.props.limitWebExstingOrders}
                                isloadingWebExistingOrders={this.props.isloadingWebExistingOrders}
                                handleOnChangeWebOrdersNotificationModalToggle={this.props.handleOnChangeWebOrdersNotificationModalToggle}
                                handelOnClickWebExistingOrder={this.props.handelOnClickWebExistingOrder}
                                clearWebExistingOrderHistory={this.props.clearWebExistingOrderHistory}
                            />
                            <IsNewOrExistingTabletOrderNotificationSelectionModal 
                                showIsNewExistingTabletOrderNotificationSelectionModal={this.props.showIsNewExistingTabletOrderNotificationSelectionModal}
                                handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal={this.props.handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal}
                                handleOnClickTabletOrderNotificationTypeSelection={this.props.handleOnClickTabletOrderNotificationTypeSelection}
                            />
                            <TabletNewOrdersNotificationModal 
                                showNewTabletOrderNotificationModal={this.props.showNewTabletOrderNotificationModal}
                                order={this.props.order}
                                onClickHandleLoadMoreNewTabletNotificationOrderItems={this.props.onClickHandleLoadMoreNewTabletNotificationOrderItems}
                                clearNewTabletNotificationOrderLimits={this.props.clearNewTabletNotificationOrderLimits}
                                limitTabletNewNotificationOrders={this.props.limitTabletNewNotificationOrders}
                                isloadingNewTabletNotificationOrders={this.props.isloadingNewTabletNotificationOrders}
                                handleOnChangeNewTabletNotificationModalToggle={this.props.handleOnChangeNewTabletNotificationModalToggle}
                                handelOnClickTabletNewOrder={this.props.handelOnClickTabletNewOrder}
                                clearNewTabletNotificationOrdersHistory={this.props.clearNewTabletNotificationOrdersHistory}
                            />
                            <TabletExistingOrdersNotificationModal 
                                showExistingTabletOrderNotificationModal={this.props.showExistingTabletOrderNotificationModal}
                                order={this.props.order}
                                onClickHandleLoadMoreExistingTabletNotificationOrderItems={this.props.onClickHandleLoadMoreExistingTabletNotificationOrderItems}
                                clearExistingTabletNotificationOrderLimits={this.props.clearExistingTabletNotificationOrderLimits}
                                limitTabletExistingNotificationOrders={this.props.limitTabletExistingNotificationOrders}
                                isloadingExistingTabletNotificationOrders={this.props.isloadingExistingTabletNotificationOrders}
                                handleOnChangeExistingTabletNotificationModalToggle={this.props.handleOnChangeExistingTabletNotificationModalToggle}
                                handelOnClickTabletNewOrder={this.props.handelOnClickTabletNewOrder}
                                clearExistingTabletNotificationOrdersHistory={this.props.clearExistingTabletNotificationOrdersHistory}
                            />

                            <FoodAppsExistingsOrderMenuOptionsModal 
                                handleOnChangeFoodAppsExistingOrderMenuOptionsModalToggle={this.props.handleOnChangeFoodAppsExistingOrderMenuOptionsModalToggle}
                                showFoodAppsExistingOrderMenuOptionsModal={this.props.showFoodAppsExistingOrderMenuOptionsModal}
                                handleOnClickViewExistingFoodAppsOrderOrder={this.props.handleOnClickViewExistingFoodAppsOrderOrder}
                                handleOnClickDeleteExistingFoodAppsOrderOrder={this.props.handleOnClickDeleteExistingFoodAppsOrderOrder}
                                handleOnClickCancelExistingFoodAppsOrderOrder={this.props.handleOnClickCancelExistingFoodAppsOrderOrder}
                            />
                            <FoodAppsExistingOrdersModal 
                                showExistingFoodAppsOrdersModal={this.props.showExistingFoodAppsOrdersModal}
                                order={this.props.order}
                                onClickHandleLoadMoreExistingFoodAppOrderItems={this.props.onClickHandleLoadMoreExistingFoodAppOrderItems}
                                clearExistingFoodAppsOrderLimits={this.props.clearExistingFoodAppsOrderLimits}
                                limitExstingFoodAppsOrders={this.props.limitExstingFoodAppsOrders}
                                isloadingExistingFoodAppsOrders={this.props.isloadingExistingFoodAppsOrders}
                                // showExistingFoodAppsOrdersModal={this.state.showExistingFoodAppsOrdersModal}
                                handleOnChangeFoodAppsExistingOrderModalToggle={this.props.handleOnChangeFoodAppsExistingOrderModalToggle}
                                handelOnClickExistingFoodAppsOrder={this.props.handelOnClickExistingFoodAppsOrder}
                                clearExistingFoodAppsOrderHistory={this.props.clearExistingFoodAppsOrderHistory}
                                btnOnClickDeleteAllExistingFoodAppOrders={this.props.btnOnClickDeleteAllExistingFoodAppOrders}
                            />
                        </div>
                        {/* <div className="col-1 text-center"> */}
                            {/* <NotePad
                                MenuCategoriesLength={menuCategories.MenuCategories.length}
                                addNotesWithExtraCharge={this.props.addNotesWithExtraCharge}
                                showNotePadModal={this.props.showNotePadModal}
                                handleOnChangeNotePadToggle={this.props.handleOnChangeNotePadToggle}
                                isMistakeActive={this.props.isMistakeActive}
                                mistake_type={this.props.mistake_type}
                            /> */}

                        {/* </div> */}
                        <Online>
                            <div className="col-4 text-right" >
                                {/* <button className="btn btn-danger"  >
                                    Pre Order
                                </button> */}
                                {/* <div className="col"> */}
                                    <button
                                        className={`btn btn-danger py-2 d-inline-flex align-items-center justify-content-center btnKiosk notificationKios btnUpperMenu`} onClick={this.props.showTabletNotificationsModal}>
                                        <p>
                                            <img src={iconKioskPng} alt="icons" className="mw-100 pr-3" />
                                            Kiosk
                                        </p>
                                        {
                                            this.props.TabletOrderNotificationsCount > 0?
                                            <span className="position-absolute">{this.props.TabletOrderNotificationsCount}</span>
                                            : null
                                        }
                                    </button>

                                {/* </div> */}
                                {/* <Online> */}
                                    {
                                        (Object.keys(this.props.order).length !== 0 && this.props.order.WebOrderNotificationsCount !== undefined) ?
                                            <Notifications
                                                // showWebOrdersNotificationModal={this.props.showWebOrdersNotificationModal}
                                                // handleOnChangeWebOrdersNotificationModalToggle={this.props.handleOnChangeWebOrdersNotificationModalToggle}
                                                showWebNotificationsModal={this.props.showWebNotificationsModal}
                                                WebOrderNotificationsCount={this.props.WebOrderNotificationsCount}
                                            />
                                        :null
                                    }
                                    {
                                        this.props.loadingOrderNotificationCount ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                        // this.state.loadingMenuCatgoeries ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                    }
                                {/* </Online> */}
                            </div>
                        </Online>
                        {/* <div className="col-2 text-right">
                            
                        </div> */}
                    </div>
                </div>
                {/* <!-- food-menus-head --> */}

                {/* <!-- food-menus-menu --> */}
                <div className="position-relative">
                    {
                        menuCategories.MenuCategories.length > 0 ?
                            <MenuCategories 
                                MenuCategories={menuCategories.MenuCategories}
                                selectedMenuCategoryId={this.props.selectedMenuCategoryId}
                                // isRefundActive={this.props.isRefundActive}
                                // selectedMenuCategoryId={this.state.menuCategoryId}
                                handleOnClickMenuCategory={this.props.handleOnClickMenuCategory}
                            />
                        :null
                    }
                    {
                        this.props.loadingMenuCatgoeries ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                        // this.state.loadingMenuCatgoeries ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                    }
                    {/* <NotePad 
                        MenuCategoriesLength={menuCategories.MenuCategories.length}
                        addNotesWithExtraCharge={this.props.addNotesWithExtraCharge}
                        showNotePadModal={this.props.showNotePadModal}
                        handleOnChangeNotePadToggle={this.props.handleOnChangeNotePadToggle}
                        isMistakeActive={this.props.isMistakeActive}
                        mistake_type={this.props.mistake_type}
                    /> */}
                    
                </div>
                {/* <!-- food-menus-menu --> */}

                {/* <!-- food-menus-footer --> */}
                <div className="food-menus-footer">
                    <div className="row">
                        <div className="col-md-3">
                            {
                                MenuItems.length  > 0 ?
                                // MenuItems !== 'undefined' && MenuItems !== undefined ?
                                <MenuItemsColumn 
                                    MenuItems={MenuItems}
                                    selectedMenuCategoryItemId={this.props.menuCategoryItemId}
                                    // selectedMenuCategoryItemId={this.state.menuCategoryItemId}
                                    limitMenuItems={this.props.limitMenuItems}
                                    // limitMenuItems={this.state.limitMenuItems}
                                    handleOnClickMenuCategoryItem={this.props.handleOnClickMenuCategoryItem}
                                />
                                :null
                            }
                            {
                                this.props.loadingMenuCatgoeryItems ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                // this.state.loadingMenuCatgoeryItems ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                            }
                        </div>
                        {
                            Object.keys(this.props.menuCategoryItem).length > 0 && this.props.isShowMenuItemSpecialModifiersCircles && (this.props.menuCategoryItem.special_modifiers && this.props.menuCategoryItem.special_modifiers.length > 0) ?
                                <div className="col-md-3">
                                    <MenuItemSpecialModifiers 
                                        menuCategoryItem={this.props.menuCategoryItem}
                                        SpecialModifiers={this.props.menuCategoryItem.special_modifiers}
                                        menuCategoryItemId={this.props.menuCategoryItemId}
                                        limitMenuItems={this.props.limitMenuItems}
                                        handleOnClickMenuCategoryMenuItemSpecialModifier={this.props.handleOnClickMenuCategoryMenuItemSpecialModifier}
                                    />
                                </div>
                            :
                            Object.keys(this.props.menuCategoryItem).length > 0 && this.props.isShowMenuItemSizeCircles && (this.props.menuCategoryItem.sizes && this.props.menuCategoryItem.sizes.length > 0) ?
                                <div className="col-md-3">
                                    <MenuItemSizes 
                                        menuCategoryItem={this.props.menuCategoryItem}
                                        Sizes={this.props.menuCategoryItem.sizes}
                                        menuCategoryItemId={this.props.menuCategoryItemId}
                                        limitMenuItems={this.props.limitMenuItems}
                                        handleOnClickMenuCategoryMenuItemSize={this.props.handleOnClickMenuCategoryMenuItemSize}
                                    />
                                </div>
                            :
                            <Fragment>
                                <div className="col-md-2">
                                    {
                                        MenuItemCombos.length  > 0 ?
                                        <MenuItemComboItems 
                                            MenuItemCombos={MenuItemCombos}
                                            limitMenuItemComboItems={this.props.limitMenuItemComboItems}
                                            // limitMenuItemComboItems={this.state.limitMenuItemComboItems}
                                            selectedMenuItemComboItemId={this.props.selectedMenuItemComboItemId}
                                            // selectedMenuItemComboItemId={this.state.selectedMenuItemComboItemId}
                                            handleOnClickMenuItemComboItem={this.props.handleOnClickMenuItemComboItem}
                                        />
                                        :null
                                    }
                                    {
                                        this.props.loadingMenuItemComboItems ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                        // this.state.loadingMenuItemComboItems ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                    }
                                </div>
                                
                                <div className="col-md-7">
                                    {/* <button type="button" className="btn btn-danger btn-circle btn-xl">SMALL</button> */}
                                    {/* <button type="button" className="btn btn-danger btn-circle btn-xl">MEDIUM</button> */}
                                    {/* <button type="button" className="btn btn-danger btn-circle btn-xl">X LARGER</button> */}
                                    {
                                        // this.props.menuCategoryItemId !== '' ?
                                            <MenuItemIngredientsTypes 
                                                selectedMenuItemIngredientTypeCategory={this.props.selectedMenuItemIngredientTypeCategory}
                                                menuCategoryItemId={this.props.menuCategoryItemId}
                                                // selectedMenuItemIngredientTypeCategory={this.state.selectedMenuItemIngredientTypeCategory}
                                                handleOnClickChangeMenuItemIngredientType={this.props.handleOnClickChangeMenuItemIngredientType}
                                                {...this.props}
                                            />
                                        // :null
                                    }
                                    <div className="row">
                                        <div className="col">
                                            {
                                                Object.keys(menuItemWithIngredient).length > 0 ?
                                                <MiscIngredients 
                                                    menuItemWithIngredient={menuItemWithIngredient}
                                                    limitMiscIngredients={this.props.limitMiscIngredients}
                                                    addIngredientToMenuItemCart={this.props.addIngredientToMenuItemCart}
                                                    // limitMiscIngredients={this.state.limitMiscIngredients}
                                                />
                                                :null
                                            }
                                            {
                                                this.props.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                                // this.state.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                            }
                                            
                                        
                                        </div>
                                        <div className="col">
                                            {
                                                Object.keys(menuItemWithIngredient).length > 0 ?
                                                <SauceIngredients 
                                                    menuItemWithIngredient={menuItemWithIngredient}
                                                    limitSauceIngredients={this.props.limitSauceIngredients}
                                                    addIngredientToMenuItemCart={this.props.addIngredientToMenuItemCart}
                                                    // limitSauceIngredients={this.state.limitSauceIngredients}
                                                />
                                                :null
                                            }
                                            {
                                                this.props.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                                // this.state.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                            }
                                            
                                            
                                        </div>
                                        <div className="col">
                                            {
                                                Object.keys(menuItemWithIngredient).length > 0 ?
                                                <MeatCheeseIngredients 
                                                    menuItemWithIngredient={menuItemWithIngredient}
                                                    limitMeatCheeseIngredients={this.props.limitMeatCheeseIngredients}
                                                    addIngredientToMenuItemCart={this.props.addIngredientToMenuItemCart}
                                                    // limitMeatCheeseIngredients={this.state.limitMeatCheeseIngredients}
                                                />
                                                :null
                                            }
                                            {
                                                this.props.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                                // this.state.loadingMenuItemWithIngredients ? <LoadingSpinner subClass={'mt10 mb10 text-center'} /> :null
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                        
                            </Fragment>
                        }
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            {
                                total_menu_items > 0 && total_menu_items > this.props.limitMenuItems ?
                                // total_menu_items > 0 && total_menu_items > this.state.limitMenuItems ?
                                    <button className="btn btn-none-bg mb-4 mt-3"  onClick={this.props.loadMoreMenuItems}>
                                        <img src={seventhPng} alt="icons" className="mw-100" />
                                    </button>
                                :null
                            }
                        </div>
                        <div className="col text-center">
                            {
                                total_menu_item_combos > 0 && total_menu_item_combos > this.props.limitMenuItemComboItems ?
                                // total_menu_item_combos > 0 && total_menu_item_combos > this.state.limitMenuItemComboItems ?
                                    <button className="btn btn-none-bg mb-4 mt-3" onClick={this.props.loadMoreMenuItemComboItems}>
                                        <img src={seventhPng} alt="icons" className="mw-100" />
                                    </button>
                               :null
                            }
                        </div>
                        <div className="col text-center">
                            {
                                total_sauce_ingredients > 0 && total_sauce_ingredients > this.props.limitSauceIngredients ?
                                // total_sauce_ingredients > 0 && total_sauce_ingredients > this.state.limitSauceIngredients ?
                                <button className="btn btn-none-bg mb-4 mt-3" onClick={this.props.loadMoreSauceIngredients}>
                                    <img src={seventhPng} alt="icons" className="mw-100" />
                                </button>
                              :null
                            }
                        </div>
                        <div className="col text-center">
                            {
                                total_meat_cheese_ingredients > 0 && total_meat_cheese_ingredients > this.props.limitMeatCheeseIngredients ?
                                // total_meat_cheese_ingredients > 0 && total_meat_cheese_ingredients > this.state.limitMeatCheeseIngredients ?
                                <button className="btn btn-none-bg mb-4 mt-3" onClick={this.props.loadMoreMeatCheeseIngredients}>
                                    <img src={seventhPng} alt="icons" className="mw-100" />
                                </button>
                                :null
                            }
                        </div>
                        <div className="col text-center">
                            {
                                total_misc_ingredients > 0 && total_misc_ingredients > this.props.limitMiscIngredients ?
                                // total_misc_ingredients > 0 && total_misc_ingredients > this.state.limitMiscIngredients ?
                                <button className="btn btn-none-bg mb-4 mt-3" onClick={this.props.loadMoreMiscIngredients}>
                                    <img src={seventhPng} alt="icons" className="mw-100" />
                                </button>
                                :null
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- food-menus-footer --> */}


            </div>

            // <!-- food-menus-head -->
        );
    }
}
function mapStateToProps(state) {
    // console.log('state in Food Menu')
    // console.log(state)
    return { 
    };
    // return { menuCategories : state.menuCategories,MenuItems : state.MenuItems};
}
export default connect(mapStateToProps, {})(FoodMenu);
/* const mapStateToProps = (state) => {
    console.log('state')
    console.log(state)
    return {
        menuCategories: state.menuCategories,
        menuItem: state.menuItem,
        menuItemWithIngredient: state.menuItemWithIngredient
    };
} */
    
/* export default connect(mapStateToProps, {
    fetchMenuCatgories,
    getMenuItemsByCategoryId,
    fetchMenuItemCombosByMenuItemId,
    fetchMenuItemByIdWithIngredients,
    clearMenuCategoryMenuItems,
    clearMenuItemComboItems,
    clearMenuItemIngredients,
})(withRouter(FoodMenu)); */