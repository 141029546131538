import axios from 'axios';
import {
    SAVE_ORDER,
    EDIT_ORDER,
    FETCH_EXISTING_ORDERS,
    FETCH_FOOD_APPS_EXISTING_ORDERS,
    CLEAR_EXISTING_FOOD_APPS_ORDERS_HISTORY,
    CLEAR_WEB_EXISTING_ORDERS_HISTORY,
    FETCH_WEB_EXISTING_ORDERS,
    FETCH_ORDER_BY_ID,
    CLEAR_ORDER_HISTORY,
    CLEAR_EXISTING_ORDERS_HISTORY,
    FETCH_MAX_ORDER_TICKET_NUMBER,
    FETCH_POS_ORDERS,
    FETCH_EXISTING_DELIVERY_ORDERS,
    CLEAR_POS_ORDERS_HISTORY,
    FETCH_MISTAKE_EXISTING_ORDERS,
    CLEAR_MISTAKE_EXISTING_ORDERS_HISTORY,
    FETCH_POS_KITCHEN_ORDERS,
    CLEAR_POS_KITCHEN_ORDERS_HISTORY,
    CHANGE_POS_KITCHEN_ORDER_STATUS,
    ADD_NEW_POS_ORDER_TO_KITCHEN_DISPLAY,
    UPDATE_POS_ORDER_TO_KITCHEN_DISPLAY,
    FETCH_WEB_ORDER_NOTIFICATIONS_COUNT,
    FETCH_TABLET_ORDER_NOTIFICATIONS_COUNT,
    FETCH_POS_TODAY_ORDERS,
    DELETE_POS_ORDER_FROM_POS_ORDERS_PAGE,
    FETCH_TODAY_EXISTING_ORDERS,
    FETCH_TODAY_MISTAKE_EXISTING_ORDERS,
    FETCH_ALL_POS_KITCHEN_ORDERS,
    DELETE_POS_ORDER_FROM_KITCHEN_DISPLAY,
    DELETE_ORDER_BY_ID,
    FETCH_NEW_TABLET_NOTIFICATION_ORDERS,
    FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS,
    CLEAR_NEW_TABLET_NOTIFICATION_ORDERS_HISTORY,
    CLEAR_EXISTING_TABLET_NOTIFICATION_ORDERS_HISTORY,
    DELETE_ALL_EXISTING_FOOD_APPS_ORDERS,
} from './types';
import { checkInternetConnection,getUniqueNameWithTimeStamp } from '../components/utils/general';
import localForage from 'localforage';
import { BASE_URL,POS_ORDER_SERVER,TABLET_ORDER_SERVER } from '../components/utils/misc';

export const submitCreateOrder = (values) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/save-order`, values);
        /* let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        if(posTodayOrders !== null && posTodayOrders.Orders.length > 0){
            let uniqueOrders = posTodayOrders.Orders.filter((order)=>{
                return order._id !== res.data.objOrder.;
            });
            posTodayOrders.Orders = uniqueOrders;
            posTodayOrders.Orders.push({...values});

            posTodayOrders.Orders.push({...values});
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(posTodayOrders))
        } */
        dispatch({ type: SAVE_ORDER, payload: res.data });
    }else{
        console.log('i am in else')
        values.order_details = values.cart;
        values.time_selection_format = values.timeSelectionRadioBox;
        values.have_ready_in_time = values.drp_default_time;
        values.other_time = values.time_picker_time;
        values.status= values.payment_method;
        values.created_from=  'pos';
        let addOrders = await localForage.getItem('new-orders');
        console.log('addOrders')
        console.log(addOrders)
        addOrders = JSON.parse(addOrders);
        console.log('addOrders after')
        console.log(addOrders)
        // debugger;
        let tempID = `temp_${getUniqueNameWithTimeStamp()}`;
        values._id = tempID;
        values.off_new_order_id = tempID;
        if(addOrders !== null && addOrders.length > 0){
            let temp = addOrders;
            temp.push({...values});
            localForage.setItem('new-orders', JSON.stringify(temp))
        }else{
            localForage.setItem('new-orders', JSON.stringify([{...values}]))
        }
        let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        if(posTodayOrders !== null && posTodayOrders.Orders.length > 0){
            posTodayOrders.total_pos_today_orders = posTodayOrders.total_pos_today_orders + 1;
            posTodayOrders.Orders.push({...values});
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(posTodayOrders))
        }else{
            let tempObj = {}
            tempObj.total_pos_today_orders = 1;
            tempObj.Orders = [{...values}];
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(tempObj))
        }
        // We will not show misatke order with done payment method on kithcn disolay
        if(values.order_type === "mistake" && values.payment_method === 'done'){

        }else if(values.is_no_kitchen){

        }else{
            let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
            allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
            if(allPosKitchenOrders !== null && allPosKitchenOrders.Orders.length > 0){
                allPosKitchenOrders.total_all_pos_kitchen_orders = allPosKitchenOrders.total_all_pos_kitchen_orders + 1;
                allPosKitchenOrders.Orders.push({...values});
                localForage.setItem('fetch-all-pos-kitchen-orders', JSON.stringify(allPosKitchenOrders))
            }else{
                let tempObj = {}
                tempObj.total_all_pos_kitchen_orders = 1;
                tempObj.Orders = [{...values}];
                localForage.setItem('fetch-all-pos-kitchen-orders', JSON.stringify(tempObj))
            }
        }
        if(values.payment_method === 'done'){
            if(values.order_type === "mistake"){
                let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
                allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
                if(allMistakeExistingOrders !== null && allMistakeExistingOrders.Orders.length > 0){
                    allMistakeExistingOrders.total_all_mistake_existing_orders = allMistakeExistingOrders.total_all_mistake_existing_orders + 1;
                    allMistakeExistingOrders.Orders.push({...values});
                    localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(allMistakeExistingOrders))
                }else{
                    let tempObj = {}
                    tempObj.total_all_mistake_existing_orders = 1;
                    tempObj.Orders = [{...values}];
                    localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(tempObj))
                }
            }else{
                let posTodayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
                posTodayExistingOrders = JSON.parse(posTodayExistingOrders);
                if(posTodayExistingOrders !== null && posTodayExistingOrders.Orders.length > 0){
                    posTodayExistingOrders.total_today_existing_orders = posTodayExistingOrders.total_today_existing_orders + 1;
                    posTodayExistingOrders.Orders.push({...values});
                    localForage.setItem('fetch-today-existing-orders', JSON.stringify(posTodayExistingOrders))
                }else{
                    let tempObj = {}
                    tempObj.total_today_existing_orders = 1;
                    tempObj.Orders = [{...values}];
                    localForage.setItem('fetch-today-existing-orders', JSON.stringify(tempObj))
                }
            }
        }
        
        let res ={
            error:false,
            msg:'Order has been placed Successfully.',
            order_id : tempID,
            objOrder: values,
        }
        dispatch({ type: SAVE_ORDER, payload: res });
    }
    // history.push('/surveys');
};
export const submitEditOrder = (values) => async dispatch => {
    let isConnected = await checkInternetConnection();
    console.log('values.order_id')
    console.log('values.order_id')
    // console.log(values)
    console.log(values.order_id)
    console.log('values._id')
    console.log(values._id)
    if(isConnected && !values.order_id.includes('temp_')){
        const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/edit-order`, values);
        dispatch({ type: EDIT_ORDER, payload: res.data });
    }else{
        values.order_details = values.cart;
        values.time_selection_format = values.timeSelectionRadioBox;
        values.have_ready_in_time = values.drp_default_time;
        values.other_time = values.time_picker_time;
        values.status= values.payment_method;
        values._id = values.order_id;
        let tempID = `temp_${getUniqueNameWithTimeStamp()}`;
        values.off_edit_order_id = tempID;
        // if no connection then save to asyncstorage 
        if(values.order_id.includes('temp_')){ // it the order has id like 'temp_...' it is a new order created offline
            let addOrders = await localForage.getItem('new-orders');
            addOrders = JSON.parse(addOrders);
            if(addOrders !== null && addOrders.length > 0){
                let temp = addOrders;
                let uniqueOrders = temp.filter((order)=>{
                    return order._id !== values.order_id;
                });
                console.log('uniqueOrders in addOrders')
                console.log(uniqueOrders)
                temp = uniqueOrders;
                temp.push({...values});
                localForage.setItem('new-orders', JSON.stringify(temp))
            }else{
                localForage.setItem('new-orders', JSON.stringify([{...values}]))
            }
        }else{
            let editOrders = await localForage.getItem('edit-orders');
            editOrders = JSON.parse(editOrders);
            if(editOrders !== null && editOrders.length > 0){
                let temp = editOrders;
                let uniqueOrders = temp.filter((order)=>{
                    return order._id !== values.order_id;
                });
                console.log('uniqueOrders in editOrders')
                console.log(uniqueOrders)
                temp = uniqueOrders;
                temp.push({...values});
                localForage.setItem('edit-orders', JSON.stringify(temp))
            }else{
                localForage.setItem('edit-orders', JSON.stringify([{...values}]))
            }
        }
        if(values.payment_method === 'cash' || values.payment_method === 'debit_card' || values.payment_method === 'credit_card' || values.payment_method === 'gift_card' ){
            if(values.order_type === "mistake"){
                let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
                allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
                if(allMistakeExistingOrders !== null && allMistakeExistingOrders.Orders.length > 0){
                    let uniqueOrders = allMistakeExistingOrders.Orders.filter((order)=>{
                        return order._id !== values.order_id;
                    });
                    allMistakeExistingOrders.Orders = uniqueOrders;
                    allMistakeExistingOrders.total_all_mistake_existing_orders = allMistakeExistingOrders.total_all_mistake_existing_orders - 1;
                }
                localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(allMistakeExistingOrders))
            }else{
                let posTodayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
                posTodayExistingOrders = JSON.parse(posTodayExistingOrders);
                if(posTodayExistingOrders !== null && posTodayExistingOrders.Orders.length > 0){
                    let uniqueOrders = posTodayExistingOrders.Orders.filter((order)=>{
                        return order._id !== values.order_id;
                    });
                    posTodayExistingOrders.Orders = uniqueOrders;
                    posTodayExistingOrders.total_today_existing_orders = posTodayExistingOrders.total_today_existing_orders - 1;
                }
                localForage.setItem('fetch-today-existing-orders', JSON.stringify(posTodayExistingOrders))
            }
        }else{
            if(values.order_type === "mistake"){
                let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
                allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
                if(allMistakeExistingOrders !== null && allMistakeExistingOrders.Orders.length > 0){
                    let uniqueOrders = allMistakeExistingOrders.Orders.filter((order)=>{
                        return order._id !== values.order_id;
                    });
                    allMistakeExistingOrders.Orders = uniqueOrders;
                    allMistakeExistingOrders.Orders.push({...values});
                    localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(allMistakeExistingOrders))
                }else{
                    let tempObj = {}
                    tempObj.total_all_mistake_existing_orders = 1;
                    tempObj.Orders = [{...values}];
                    localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(tempObj))
                }
            }else{
                let posTodayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
                posTodayExistingOrders = JSON.parse(posTodayExistingOrders);
                if(posTodayExistingOrders !== null && posTodayExistingOrders.Orders.length > 0){
                    let uniqueOrders = posTodayExistingOrders.Orders.filter((order)=>{
                        return order._id !== values.order_id;
                    });
                    posTodayExistingOrders.Orders = uniqueOrders;
                    posTodayExistingOrders.Orders.push({...values});
                    localForage.setItem('fetch-today-existing-orders', JSON.stringify(posTodayExistingOrders))
                }else{
                    let tempObj = {}
                    tempObj.total_today_existing_orders = 1;
                    tempObj.Orders = [{...values}];
                    localForage.setItem('fetch-today-existing-orders', JSON.stringify(tempObj))
                }
            }
            
        }

        let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        if(posTodayOrders !== null && posTodayOrders.Orders.length > 0){
            let uniqueOrders = posTodayOrders.Orders.filter((order)=>{
                return order._id !== values.order_id;
            });
            posTodayOrders.Orders = uniqueOrders;
            posTodayOrders.Orders.push({...values});
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(posTodayOrders))
        }else{
            let tempObj = {}
            tempObj.total_pos_today_orders = 1;
            tempObj.Orders = [{...values}];
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(tempObj))
        }
        if(values.order_type === "mistake" && values.payment_method === 'done'){

        }else if(values.is_no_kitchen){

        }else{
            let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
            allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
            if(allPosKitchenOrders !== null && allPosKitchenOrders.Orders.length > 0){
                let uniqueOrders = allPosKitchenOrders.Orders.filter((order)=>{
                    return order._id !== values.order_id;
                });
                allPosKitchenOrders.Orders = uniqueOrders;
                allPosKitchenOrders.Orders.push({...values});
                localForage.setItem('fetch-all-pos-kitchen-orders', JSON.stringify(allPosKitchenOrders))
            }else{
                let tempObj = {}
                tempObj.total_all_pos_kitchen_orders = 1;
                tempObj.Orders = [{...values}];
                localForage.setItem('fetch-all-pos-kitchen-orders', JSON.stringify(tempObj))
            }
        }
        
        
        let res ={
            error:false,
            msg:'Order has been edited Successfully.',
            order_id : values.order_id,
            objOrder: values,
        }
        dispatch({ type: EDIT_ORDER, payload: res });
    }
};
export const fetchOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-existing-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_existing_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_EXISTING_ORDERS, payload: res });
    }else{
        let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
        todayExistingOrders = JSON.parse(todayExistingOrders);
        todayExistingOrders.total_existing_orders = todayExistingOrders.total_today_existing_orders;
        // console.log('todayExistingOrders in FETCH_EXISTING_ORDERS')
        // console.log(todayExistingOrders)
        dispatch({ type: FETCH_EXISTING_ORDERS, payload:todayExistingOrders });
    }
};

export const fetchFoodAppOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-food-apps-existing-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_food_apps_existing_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_FOOD_APPS_EXISTING_ORDERS, payload: res });
    }else{
        // let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
        // todayExistingOrders = JSON.parse(todayExistingOrders);
        // todayExistingOrders.total_existing_orders = todayExistingOrders.total_today_existing_orders;
        // // console.log('todayExistingOrders in FETCH_EXISTING_ORDERS')
        // // console.log(todayExistingOrders)
        // dispatch({ type: FETCH_FOOD_APPS_EXISTING_ORDERS, payload:todayExistingOrders });
    }
};

export const fetchWebOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    const data = {
        created_from,
        order_type,
        payment_method,
        limit,
        skip,
    }
    const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-web-orders`,data);
    let newState = [
        ...previousState,
        ...response.data.Orders
    ];
    /* console.log('previousState')
    console.log(previousState)
    console.log('newState in react')
    console.log(newState) */
    let res = {
        total_web_existing_orders: response.data.total_orders,
        Orders: newState
    }
    // console.log('res')
    // console.log(res)
    dispatch({ type: FETCH_WEB_EXISTING_ORDERS, payload: res });
};

export const clearExistingOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_EXISTING_ORDERS_HISTORY, payload: [] });
};

export const clearExistingFoodAppsOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_EXISTING_FOOD_APPS_ORDERS_HISTORY, payload: [] });
};

export const clearWebExistingOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_WEB_EXISTING_ORDERS_HISTORY, payload: [] });
};

export const fetchPosOrders = (created_from, skip, limit, previousState = []) => async dispatch => {
    let isConnected = await await checkInternetConnection();
    console.log('isConnected in fetchPosOrders')
    console.log(isConnected)
    if(isConnected){
        const data = {
            created_from,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-pos-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_pos_orders: response.data.total_orders,
            Orders: newState,
            is_offline: false,
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_POS_ORDERS, payload: res });
    }else{
        let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        posTodayOrders.is_offline = true;
        posTodayOrders.total_pos_orders = posTodayOrders.total_pos_today_orders;
        dispatch({ type: FETCH_POS_ORDERS, payload:posTodayOrders });
    }
};

export const clearPosOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_POS_ORDERS_HISTORY, payload: '' });
};

export const clearPosKitchenOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_POS_KITCHEN_ORDERS_HISTORY, payload: '' });
};

export const fetchKitchenOrders = (created_from, skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    // console.log('isConnected in fetchKitchenOrders')
    // console.log(isConnected)
    if(isConnected){
        const data = {
            created_from,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-pos-kitchen-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_pos_kitchen_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_POS_KITCHEN_ORDERS, payload: res });
    }else{
        let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
        allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
        allPosKitchenOrders.total_pos_kitchen_orders= allPosKitchenOrders.total_all_pos_kitchen_orders;
        dispatch({ type: FETCH_POS_KITCHEN_ORDERS, payload:allPosKitchenOrders });
    }
};

export const clearKitchenOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_POS_KITCHEN_ORDERS_HISTORY, payload: '' });
};

export const clearOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_ORDER_HISTORY, payload: '' });
};

export const fetchOrderByOId = (order_id,order_type='existing') => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected && !order_id.includes('temp_')){
        const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-order-by-oid?id=${order_id}`);
        dispatch({ type: FETCH_ORDER_BY_ID, payload: res.data });
    }else{
        if(order_id.includes('temp_')){
            let newOrders = await localForage.getItem('new-orders');
            newOrders = JSON.parse(newOrders);
            console.log('newOrders in ')
            console.log('newOrders in ')
            console.log('newOrders in ')
            console.log('newOrders in ')
            console.log('newOrders in ')
            console.log('newOrders in ')
            console.log(newOrders)
            if(newOrders !== null && newOrders.length > 0){
                let objOrder = newOrders.find(o => o._id === order_id);
                console.log(objOrder)
                console.log(objOrder)
                let res = {
                    order:objOrder
                }
                dispatch({ type: FETCH_ORDER_BY_ID, payload:res });
            }else{
                let res = {
                    order:{}
                }
                dispatch({ type: FETCH_ORDER_BY_ID, payload:res });
            }
        }else{
            if(order_type === 'existing'){
                let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
                todayExistingOrders = JSON.parse(todayExistingOrders);
                console.log('todayExistingOrders')
                console.log(todayExistingOrders)
                let objOrder = todayExistingOrders.Orders.find(o => o._id === order_id);
                let res = {
                    order:objOrder
                }
                dispatch({ type: FETCH_ORDER_BY_ID, payload:res });
            }else if(order_type === 'mistake'){
                let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
                allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
                let objOrder = allMistakeExistingOrders.Orders.find(o => o._id === order_id);
                let res = {
                    order:objOrder
                }
                dispatch({ type: FETCH_ORDER_BY_ID, payload:res });  
            }
        }
    }
};

export const fetchWebOrderNotificationsCount = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-order-notifications-count`);
    
    dispatch({ type: FETCH_WEB_ORDER_NOTIFICATIONS_COUNT, payload: res.data });
};

export const fetchTabletOrderNotificationsCount = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-tablet-order-notifications-count`);
    
    dispatch({ type: FETCH_TABLET_ORDER_NOTIFICATIONS_COUNT, payload: res.data });
};

export const fetchMaxTicketNumberOfOrder = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-max-ticket-number-order`);
    
    dispatch({ type: FETCH_MAX_ORDER_TICKET_NUMBER, payload: res.data });
};


export const changeKitchenOrderStatus = (status,order_id) => async dispatch => {
    let isConnected = await checkInternetConnection();
    const data = {
        status,
        order_id,
        created_at : Date.now(),
    }
    if(isConnected){
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/change-kitchen-order-status`,data);
        // console.log('res')
        // console.log(res)
        dispatch({ type: CHANGE_POS_KITCHEN_ORDER_STATUS, payload: response.data });
    }else{
        let posKitchenOrderStatusChange = await localForage.getItem('pos-kitchen-order-status-change');
        posKitchenOrderStatusChange = JSON.parse(posKitchenOrderStatusChange);
        if(posKitchenOrderStatusChange !== null && posKitchenOrderStatusChange.length > 0){
            posKitchenOrderStatusChange.push({...data});
            localForage.setItem('pos-kitchen-order-status-change', JSON.stringify(posKitchenOrderStatusChange))
        }else{
            posKitchenOrderStatusChange = [{...data}];
            localForage.setItem('pos-kitchen-order-status-change', JSON.stringify(posKitchenOrderStatusChange))
        }
        let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
        allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
        let objOrder = allPosKitchenOrders.Orders.find(o => o._id === order_id);
        console.log('objOrder')
        console.log(objOrder)
        objOrder.kitchen_status = status;
        let response = {
            error:false,
            msg:'Order status has been changed Successfully.',
            order_id : order_id,
            objOrder: objOrder,
        }
        dispatch({ type: CHANGE_POS_KITCHEN_ORDER_STATUS, payload: response });
    }
    
};

export const fetchMistakeOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-mistake-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_mistake_existing_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_MISTAKE_EXISTING_ORDERS, payload: res });
    }else{
        let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
        allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
        allMistakeExistingOrders.total_mistake_existing_orders = allMistakeExistingOrders.total_all_mistake_existing_orders
        dispatch({ type: FETCH_MISTAKE_EXISTING_ORDERS, payload:allMistakeExistingOrders });   
    }
};

export const addNewPosOrderToKitchenDisplay = (order) => async dispatch => {
    dispatch({ type: ADD_NEW_POS_ORDER_TO_KITCHEN_DISPLAY, payload: order });
};
export const updtaePosOrderToKitchenDisplay = (order) => async dispatch => {
    dispatch({ type: UPDATE_POS_ORDER_TO_KITCHEN_DISPLAY, payload: order });
};
export const deleteKitchenOrder = (order_id) => async dispatch => {
    dispatch({ type: DELETE_POS_ORDER_FROM_KITCHEN_DISPLAY, payload: order_id });
};
export const deleteOrderFromPosOrdersPage = (order_id) => async dispatch => {
    dispatch({ type: DELETE_POS_ORDER_FROM_POS_ORDERS_PAGE, payload: order_id });
};

export const clearMistakeExistingOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_MISTAKE_EXISTING_ORDERS_HISTORY, payload: '' });
};
export const fetchPosTodayOrders = (created_from,currentDate ,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            currentDate,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-today-pos-orders`,data);
        // we dont need previous state here because we are fetching all the records here
        let newState = [
            // ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_pos_today_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        localForage.setItem('fetch-today-pos-orders',JSON.stringify(res))
        dispatch({ type: FETCH_POS_TODAY_ORDERS, payload: res });
    }else{
        let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        dispatch({ type: FETCH_POS_TODAY_ORDERS, payload:posTodayOrders });
    }
};

export const fetchTodayExistingOrders= (created_from,order_type,payment_method ,currentDate,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            currentDate,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-today-existing-orders`,data);
        // we dont need previous state here because we are fetching all the records here
        let newState = [
            // ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_today_existing_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        localForage.setItem('fetch-today-existing-orders',JSON.stringify(res))
        dispatch({ type: FETCH_TODAY_EXISTING_ORDERS, payload: res });
    }else{
        let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
        todayExistingOrders = JSON.parse(todayExistingOrders);
        dispatch({ type: FETCH_TODAY_EXISTING_ORDERS, payload:todayExistingOrders });
    }
};

export const fetchTodayMistakeOrders = (created_from,order_type,payment_method ,currentDate,skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            currentDate,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-all-mistake-orders`,data);
        // we dont need previous state here because we are fetching all the records here
        let newState = [
            // ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_all_mistake_existing_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        localForage.setItem('fetch-all-mistake-existing-orders',JSON.stringify(res))
        dispatch({ type: FETCH_TODAY_MISTAKE_EXISTING_ORDERS, payload: res });
    }else{
        let todayMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
        todayMistakeExistingOrders = JSON.parse(todayMistakeExistingOrders);
        dispatch({ type: FETCH_TODAY_MISTAKE_EXISTING_ORDERS, payload:todayMistakeExistingOrders });
    }
};

export const fetchAllKitchenOrders = (created_from, skip, limit, previousState = []) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected){
        const data = {
            created_from,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/fetch-all-pos-kitchen-orders`,data);
        // we dont need previous state here because we are fetching all the records here
        let newState = [
            // ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_all_pos_kitchen_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        localForage.setItem('fetch-all-pos-kitchen-orders',JSON.stringify(res))
        dispatch({ type: FETCH_ALL_POS_KITCHEN_ORDERS, payload: res });
    }else{
        let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
        allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
        dispatch({ type: FETCH_ALL_POS_KITCHEN_ORDERS, payload:allPosKitchenOrders });
    }
};

export const deleteOrderByOId = (order_id,values) => async dispatch => {
    let isConnected = await checkInternetConnection();
    if(isConnected && !order_id.includes('temp_')){
        // if(isConnected && !order_id.includes('temp_')){
        const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/delete-order-by-oid`,{order_id});
        dispatch({ type: DELETE_ORDER_BY_ID, payload: res.data });
    }else{
        // if no connection then save to asyncstorage 
        if(order_id.includes('temp_')){ // it the order has id like 'temp_...' it is a new order created offline
            let addOrders = await localForage.getItem('new-orders');
            addOrders = JSON.parse(addOrders);
            if(addOrders !== null && addOrders.length > 0){
                let uniqueOrders = addOrders.filter((order)=>{
                    return order._id !== order_id;
                });
                localForage.setItem('new-orders', JSON.stringify(uniqueOrders))
            }
        }else{
            let editOrders = await localForage.getItem('edit-orders');
            editOrders = JSON.parse(editOrders);
            if(editOrders !== null && editOrders.length > 0){
                let uniqueOrders = editOrders.filter((order)=>{
                    return order._id !== order_id;
                });
                localForage.setItem('edit-orders', JSON.stringify(uniqueOrders))
            }
            // Now if the order is from the database order then save it into delete-order so that we can delete it when interent is onlin
            let deletedOrders = await localForage.getItem('delete-orders');
            deletedOrders = JSON.parse(deletedOrders);
            if(deletedOrders !== null && deletedOrders.length > 0){
                let temp = deletedOrders;
                let uniqueOrders = temp.filter((order)=>{
                    return order._id !== order_id;
                });
                console.log('uniqueOrders in deletedOrders')
                console.log(uniqueOrders)
                temp = uniqueOrders;
                temp.push({order_id});
                localForage.setItem('delete-orders', JSON.stringify(temp))
            }else{
                localForage.setItem('delete-orders', JSON.stringify([{order_id}]))
            }
        }
        if(values.order_type === "mistake"){
            let allMistakeExistingOrders = await localForage.getItem('fetch-all-mistake-existing-orders');
            allMistakeExistingOrders = JSON.parse(allMistakeExistingOrders);
            if(allMistakeExistingOrders !== null && allMistakeExistingOrders.Orders.length > 0){
                let uniqueOrders = allMistakeExistingOrders.Orders.filter((order)=>{
                    return order._id !== order_id;
                });
                allMistakeExistingOrders.Orders = uniqueOrders;
                allMistakeExistingOrders.total_all_mistake_existing_orders = allMistakeExistingOrders.total_all_mistake_existing_orders - 1;
            }
            localForage.setItem('fetch-all-mistake-existing-orders', JSON.stringify(allMistakeExistingOrders))
        }else{
            let posTodayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
            posTodayExistingOrders = JSON.parse(posTodayExistingOrders);
            if(posTodayExistingOrders !== null && posTodayExistingOrders.Orders.length > 0){
                let uniqueOrders = posTodayExistingOrders.Orders.filter((order)=>{
                    return order._id !== order_id;
                });
                posTodayExistingOrders.Orders = uniqueOrders;
                posTodayExistingOrders.total_today_existing_orders = posTodayExistingOrders.total_today_existing_orders - 1;
            }
            localForage.setItem('fetch-today-existing-orders', JSON.stringify(posTodayExistingOrders))
        }

        let posTodayOrders = await localForage.getItem('fetch-today-pos-orders');
        posTodayOrders = JSON.parse(posTodayOrders);
        if(posTodayOrders !== null && posTodayOrders.Orders.length > 0){
            let uniqueOrders = posTodayOrders.Orders.filter((order)=>{
                return order._id !== order_id;
            });
            posTodayOrders.Orders = uniqueOrders;
            posTodayOrders.total_pos_today_orders = posTodayOrders.total_pos_today_orders - 1;
            localForage.setItem('fetch-today-pos-orders', JSON.stringify(posTodayOrders))
        }

        let allPosKitchenOrders = await localForage.getItem('fetch-all-pos-kitchen-orders');
        allPosKitchenOrders = JSON.parse(allPosKitchenOrders);
        if(allPosKitchenOrders !== null && allPosKitchenOrders.Orders.length > 0){
            let uniqueOrders = allPosKitchenOrders.Orders.filter((order)=>{
                return order._id !== order_id;
            });
            allPosKitchenOrders.Orders = uniqueOrders;
            allPosKitchenOrders.total_all_pos_kitchen_orders = allPosKitchenOrders.total_all_pos_kitchen_orders - 1;
            localForage.setItem('fetch-all-pos-kitchen-orders', JSON.stringify(allPosKitchenOrders))
        }
        
        
        let res ={
            error:false,
            msg:'Order has been deleted successfully.',
        }
        dispatch({ type: DELETE_ORDER_BY_ID, payload: res });
    }

};

export const deleteAllExistingFoodAppsOrders = () => async dispatch => {
    const res = await axios.post(`${BASE_URL}${POS_ORDER_SERVER}/delete-all-existing-food-apps-orders`,{});
    dispatch({ type: DELETE_ALL_EXISTING_FOOD_APPS_ORDERS, payload: res.data });
};

// Tablet Orders
export const fetchNewTabletNotificationOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    // let isConnected = await checkInternetConnection();
    // if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${TABLET_ORDER_SERVER}/fetch-new-notification-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_new_tablet_notification_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_NEW_TABLET_NOTIFICATION_ORDERS, payload: res });
    // }else{
    //     let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
    //     todayExistingOrders = JSON.parse(todayExistingOrders);
    //     todayExistingOrders.total_existing_orders = todayExistingOrders.total_today_existing_orders;
    //     // console.log('todayExistingOrders in FETCH_NEW_TABLET_NOTIFICATION_ORDERS')
    //     // console.log(todayExistingOrders)
    //     dispatch({ type: FETCH_NEW_TABLET_NOTIFICATION_ORDERS, payload:todayExistingOrders });
    // }
};
export const fetchExistingTabletNotificationOrders = (created_from,order_type,payment_method ,skip, limit, previousState = []) => async dispatch => {
    // let isConnected = await checkInternetConnection();
    // if(isConnected){
        const data = {
            created_from,
            order_type,
            payment_method,
            limit,
            skip,
        }
        const response = await axios.post(`${BASE_URL}${TABLET_ORDER_SERVER}/fetch-existing-notification-orders`,data);
        let newState = [
            ...previousState,
            ...response.data.Orders
        ];
        /* console.log('previousState')
        console.log(previousState)
        console.log('newState in react')
        console.log(newState) */
        let res = {
            total_existing_tablet_notification_orders: response.data.total_orders,
            Orders: newState
        }
        // console.log('res')
        // console.log(res)
        dispatch({ type: FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS, payload: res });
    // }else{
    //     let todayExistingOrders = await localForage.getItem('fetch-today-existing-orders');
    //     todayExistingOrders = JSON.parse(todayExistingOrders);
    //     todayExistingOrders.total_existing_orders = todayExistingOrders.total_today_existing_orders;
    //     // console.log('todayExistingOrders in FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS')
    //     // console.log(todayExistingOrders)
    //     dispatch({ type: FETCH_EXISTING_TABLET_NOTIFICATION_ORDERS, payload:todayExistingOrders });
    // }
};
export const clearNewTabletNotificationOrdersHistory = () => async dispatch => {
    dispatch({ type:  CLEAR_NEW_TABLET_NOTIFICATION_ORDERS_HISTORY, payload: [] });
};

export const clearExistingTabletNotificationOrdersHistory = () => async dispatch => {
    dispatch({ type:  CLEAR_EXISTING_TABLET_NOTIFICATION_ORDERS_HISTORY, payload: [] });
};

export const fetchExistingDeliveryOrders = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-existing-delivery-orders`);
    dispatch({ type: FETCH_EXISTING_DELIVERY_ORDERS, payload: res.data });
    return Promise.resolve(res);
};