import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FoodAppsCustomerInformationModalBodyForm from './FoodAppsCustomerInformationModalBodyForm';
import Script from 'react-load-script';
// import ScriptLoader from 'react-render-props-script-loader'
import { showMsg, validatePhoneNumber, getDeliveryAmount } from '../../../../../utils/general';
import { BASE_URL, USER_SERVER } from '../../../../../utils/misc';
import moment from 'moment';
import { checkInternetConnection, checkInternetConnectionWithBrowserNavigation } from '../../../../../utils/general'
// const google = window.google;
class FoodAppsCustomerInformationModal extends React.Component {
  constructor(props) {
    super(props);
    /* this.state = {
        modal: props.showFoodAppsCustomerInformationModal
    }; */
    // Bind Functions
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

    this.toggle = this.toggle.bind(this);
    this.phoneNumberInput = React.createRef();
    // this.customerDetailForm = React.createRef();
  }
  state = {
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB3TiX15TxXzpHOnhV3s66ETx_N62AbxXU&libraries=places',
    // selectedDeliveryPaymentMethod:'',
  };
  async handleScriptLoad() {
    // Declare Options For Autocomplete
    /* var options = {
      types: ['(cities)'],
    }; */

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const isConnected = await checkInternetConnection();
    if (isConnected) {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        //   options,
      );


      // Fire Event when a suggested name is selected
      this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

      this.DistanceMatrixServiceG = new google.maps.DistanceMatrixService;
    }
    // this.calculateDistance();

  }

  handlePlaceSelect() {

    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;
    // console.log('addressObject')
    // console.log('addressObject')
    // console.log('addressObject')
    // console.log('addressObject')
    // console.log(addressObject)
    // Check if address is valid
    if (address) {
      // Set State
      this.props.handleOnChangeCustomerDetailsState({
        city: address[0].long_name,
        query: addressObject.formatted_address,
      })
      /* this.setState(
          {
              city: address[0].long_name,
              query: addressObject.formatted_address,
          }
      ); */
    }
  }

  calculateDistance() {
    let origin = this.props.origin;
    let destination = this.props.query;
    // console.log('this.props.query in calculateDistance')
    // console.log(this.props.query)
    return new Promise((resolve, reject) => {
      // console.log('this.DistanceMatrixServiceG')
      // console.log('this.DistanceMatrixServiceG')
      // console.log(this.DistanceMatrixServiceG)
      // console.log('this.DistanceMatrixServiceG.getDistanceMatrix')
      // console.log(this.DistanceMatrixServiceG.getDistanceMatrix)
      if (this.DistanceMatrixServiceG !== undefined) {
        this.DistanceMatrixServiceG.getDistanceMatrix({
          origins: [origin],
          destinations: [destination],
          travelMode: 'DRIVING',
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        }, function (response, status) {
          resolve({ response, status });
        });
      } else {
        resolve({ response: 'google maps is not deinfed', status: 'error' });
      }
    });
  }
  inputAddressChangedHandler = (event) => {
    const updatedKeyword = event.target.value;
    // console.log('updatedKeyword in inputAddressChangedHandler')
    // console.log(updatedKeyword)

    /* this.setState(
        {
        //   city: address[0].long_name,
          query: updatedKeyword,
        }
    ); */
    this.props.handleOnChangeCustomerDetailsState({
      //   city: address[0].long_name,
      query: updatedKeyword,
    })
    // May be call for search result
  }

  ModalLoaded = async () => {
    // console.log('this.phoneNumberInput')
    // console.log('this.phoneNumberInput')
    // console.log('this.phoneNumberInput')
    // console.log(this.phoneNumberInput)
    // console.log('this.customerDetailForm')
    // console.log('this.customerDetailForm')
    // console.log('this.customerDetailForm')
    // console.log(this.customerDetailForm)
    // this.phoneNumberInput.current.focus();
    // console.log('ModalLoaded')
    // console.log('ModalLoaded')
    const isConnected = await checkInternetConnection();
    if (isConnected) {
      this.handleScriptLoad()
    }
    // May be call for search result
  }

  toggle() {
    /* this.setState({
        modal: !this.props.showFoodAppsCustomerInformationModal
        // modal: !this.props.modal
    }); */
    this.props.handleOnChangeFoodAppsCustomerModalToggle(!this.props.showFoodAppsCustomerInformationModal)
    /* this.setState(prevState => ({
        modal: !prevState.modal
    })); */
  }
  handelOnChangeTimePicker = (value) => {
    // const format = 'h:mm a';
    // const format = 'hh:mm A';
    const format = 'MMM D YYYY HH:mm:ss';
    /* console.log(value && value.format('MMM D YYYY HH:mm:ss'));
    console.log(value && value.format('HH:mm:ss'));
    console.log(value && value.format(format));
    console.log(new Date(value.format('MMM D YYYY HH:mm:ss')))
    console.log(new Date(value.format('MMM D YYYY HH:mm:ss'))) */

    // console.log('vlue of the TimePicker in handelOnChangeTimePicker')
    // console.log(value)
    // console.log(value.format(format))
    // console.log(value && value.format(format));
    // this.setState({time_picker_time:value.format(format)})
    const selectedDate = new Date(value.format(format));
    const todayDateTime = new Date();

    if (selectedDate > todayDateTime) {
      this.props.handleOnChangeCustomerDetailsState({ time_picker_time: value.format(format) })
      // this.setState({otherTimeError:true})
    } else {
      // this.setState({otherTimeError:true})
      showMsg('Please select time greater then current time', 'error');
    }
  }
  handelOnChangeTimeKeeper = (value) => {
    // console.log('value in handelOnChangeTimeKeeper')
    // console.log('value in handelOnChangeTimeKeeper')
    // console.log('value in handelOnChangeTimeKeeper')
    // console.log('value in handelOnChangeTimeKeeper')
    // console.log('value in handelOnChangeTimeKeeper')
    // console.log(value)
    // console.log(moment())
    let date = new Date();
    date = moment(date);
    // date = moment(date).minutes(value.hour).hours(value.minute);
    date.set({ hour: value.hour, minute: value.minute })
    // console.log('date')
    // console.log(date.toString())
    // console.log(date.format("MMM D YYYY HH:mm:ss"))
    // console.log(moment(value.formatted12).format("DD-MM-YYYY kk:mm"))
    // const format = 'MMM D YYYY HH:mm:ss';

    this.props.handleOnChangeCustomerDetailsState({ time_picker_time: date })
    // const selectedDate = new Date(date);
    // const todayDateTime = new Date();
    /* if(selectedDate > todayDateTime){
        this.props.handleOnChangeCustomerDetailsState({time_picker_time:date})
        // this.setState({otherTimeError:true})
    }else{
        // this.setState({otherTimeError:true})
        showMsg('Please select time greater then current time','error');
    } */
  }


  onSubmitForm = async (values) => {
    console.log('values in submit')
    console.log('values in submit')
    console.log('values in submit')
    console.log('values in submit')
    console.log(values)
    // console.log('this.props.time_picker_time')
    // console.log('this.props.time_picker_time')
    // console.log('this.props.time_picker_time')
    // console.log(this.props.time_picker_time)

    // const isConnected = await checkInternetConnection();
    // const isConnectedBrowser = await checkInternetConnectionWithBrowserNavigation();
    // console.log('isConnected in onSubmitForm')
    // console.log('isConnected in onSubmitForm')
    // console.log('isConnected in onSubmitForm')
    // console.log(isConnected)
    // console.log('this.props.phone_no')
    // console.log(this.props.phone_no)
    // let objPhoneValidation = validatePhoneNumber(this.props.phone_no);
    // if(isConnectedBrowser && objPhoneValidation.err){
    //     showMsg(objPhoneValidation.msg,'error');
    //     return false;
    // }
    if (this.props.card_processing_fee !== false && this.props.selectedOrderType === 'delivery' && this.props.selectedDeliveryPaymentMethod === '') {
      showMsg('Please select how will you pay the delivery driver when they arrive?', 'error');
      return false;
    }
    // this.setState({isCustomerSavingLoader:true});
    this.props.handleOnChangeCustomerDetailsState({ isCustomerSavingLoader: true })
    // console.log('wait');
    // let {response,status} = await this.calculateDistance();

    // this.setState({isCustomerSavingLoader:false});
    this.props.handleOnChangeCustomerDetailsState({ isCustomerSavingLoader: false })

    let delivery_amount = 0.00;
    let distance_in_kilo = 0;
    // if ((this.props.isMistakeActive && this.props.mistake_type === 'new') || this.props.selectedOrderType === 'pickup') {
    //   this.props.handleOnChangeCustomerDetailsState({
    //     delivery_amount,
    //     distance_in_kilo,
    //   })
    // } else {
      // if (isConnected) {
      //   let { response, status } = await this.calculateDistance();
      //   console.log('response')
      //   console.log(response)
      //   console.log('status')
      //   console.log(status)
      //   if (status !== 'OK') {
      //     let someIdDashboard = document.getElementById('someId');
      //     if (someIdDashboard) {
      //       someIdDashboard.remove();
      //     }
      //     this.setState({ state: this.state });
      //     this.props.changeKeyname();
      //     // this.forceUpdate()
      //     this.setState({ src: Math.random().toString(36).substring(7) }, () => {
      //       this.setState({ src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB3TiX15TxXzpHOnhV3s66ETx_N62AbxXU&libraries=places' }, () => {
      //         this.handleScriptLoad();
      //       })
      //     })
      //     alert('Google Maps lost internet connection,We are refreshing the google maps kindly try it again. ');
      //     // alert('Google Maps lost internet connection, please shut down your POS and reopen it to correct this problem. ');
      //     return false;
      //     // alert('Please try again their was a error occured from Google Maps Api: ' + status);
      //     // alert('Error was in calculateDistance: ' + status);
      //   } else {
      //     // let originList = response.originAddresses;
      //     // let destinationList = response.destinationAddresses;

      //     if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
      //       alert("Please choose the address with in the city. There are no roads between " + this.props.origin + " and " + this.props.query);
      //       return false;
      //     } else if (response.rows[0].elements[0].status === "NOT_FOUND") {
      //       alert("Google Maps can not locate you please input a valid location " + this.props.origin + " and " + this.props.query);
      //       return false;
      //     } else {
      //       let distance = response.rows[0].elements[0].distance;
      //       // let duration = response.rows[0].elements[0].duration;
      //       // console.log(response.rows[0].elements[0].distance);
      //       distance_in_kilo = Math.ceil(distance.value / 1000); // the kilom
      //       // let distance_in_mile = distance.value / 1609.34; // the mile


      //       let delivery_amount = 0;
      //       const delivery_fees = this.props.deliveryFees;
      //       if (Object.keys(delivery_fees).length > 0) {
      //         delivery_amount = getDeliveryAmount(delivery_amount, delivery_fees, distance_in_kilo);
      //       }
      //       // alert(delivery_amount);
      //       if (delivery_amount > 50) {
      //         // alert('Error: Please enter a valid address');
      //         showMsg('Error: Please enter a valid address', 'error');
      //         return false;
      //       }
      //       // Thanos has asked to make the delivery fee 0 for order type delivery
      //       // delivery_amount = 0.00;
      //       this.props.handleOnChangeCustomerDetailsState({
      //         delivery_amount,
      //         distance_in_kilo,
      //       })
      //     }

      //   }
      // } else {
      //   this.props.handleOnChangeCustomerDetailsState({
      //     delivery_amount,
      //     distance_in_kilo,
      //     query: values.address,
      //     phone_no: values.phone_no,
      //   })
      // }
    // }
    // let query, phone_no = '';
    // if (isConnectedBrowser) {
    //   query = this.props.query;
    //   // phone_no = this.props.phone_no;
    // } else {
    //   query = values.address;
    //   // phone_no = values.phone_no;
    // }
    // phone_no = values.phone_no;
    // if ((this.props.isMistakeActive && this.props.mistake_type === 'new') || this.props.selectedOrderType === 'pickup') {
    //   values.time_picker_time = this.props.time_picker_time;
    //   values.delivery_amount = this.props.delivery_amount;
    //   values.distance_in_kilo = this.props.distance_in_kilo;
    //   values.address = query;
    //   values.query = query;
    //   values.phone_no = phone_no;
    //   values.selectedDeliveryPaymentMethod = this.props.selectedDeliveryPaymentMethod;
    //   this.props.handleOnChangeCustomerDetailsState({ customerFormData: values })
    //   this.props.handleOnChangeCustomerData(values);
    //   this.toggle()
    // } else {
      // if (query === '') {
      //   // if(this.props.query === ''){
      //   showMsg("Please fill out the address.", 'error');
      // } 
      // else if (!this.props.isMistakeActive && values.timeSelectionRadioBox === "other_time" && this.props.time_picker_time === "") {
      //   showMsg("Please select time for the order.", 'error');
      // } else if (!this.props.isMistakeActive && values.timeSelectionRadioBox === "have_ready_in" && (!values.drp_default_time || values.drp_default_time === "")) {
      //   showMsg("Please select time for the order.", 'error');
      // } 
      // else {
      //   if (!this.props.isMistakeActive && values.timeSelectionRadioBox === "other_time") {
      //     if (this.props.time_picker_time !== '') {
      //       const selectedDate = new Date(this.props.time_picker_time);
      //       const todayDateTime = new Date();
      //       if (selectedDate > todayDateTime) {
      //         // this.props.handleOnChangeCustomerDetailsState({time_picker_time:date})
      //         // this.setState({otherTimeError:true})
      //       } else {
      //         // this.setState({otherTimeError:true})
      //         showMsg('Please select time greater then current time', 'error');
      //         return false;
      //       }
      //     } else {
      //       showMsg("Please select time from clock on the customer detail form.", 'error');
      //       return false;
      //     }
      //   }
        values.time_picker_time = this.props.time_picker_time;
        values.delivery_amount = this.props.delivery_amount;
        values.distance_in_kilo = this.props.distance_in_kilo;
        // values.address = query;
        // values.query = query;
        // values.phone_no = phone_no;
        values.selectedDeliveryPaymentMethod = this.props.selectedDeliveryPaymentMethod;

        this.props.handleOnChangeCustomerDetailsState({ customerFormData: values })
        this.props.handleOnChangeCustomerData(values);
        this.toggle()
        // alert('toggle')
      // }
    // }

  }

  onChangeAutoSuggestion = (event, { newValue, method }) => {
    // console.log('newValue')
    // console.log('newValue')
    // console.log(newValue)
    // this.setState({ value: newValue });
    this.props.handleOnChangeCustomerDetailsState({ value: newValue, phone_no: newValue })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    // fetch(`https://swapi.co/api/people/?search=${value}`)
    fetch(`${BASE_URL}${USER_SERVER}/search-by-phone/?phone=${value}`)
      .then(response => response.json())
      // .then(data => this.setState({ suggestions: data.results }))
      .then(data => this.props.handleOnChangeCustomerDetailsState({ suggestions: data.results }))
  }
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {


    this.props.handleOnChangeCustomerDetailsState({ selectedPhoneNumber: suggestion, phone_no: suggestion.phone_no })
    // this.props.handleOnChangeCustomerDetailsState({selectedPhoneNumber:suggestion,phone_no:suggestion.phone_no})
    if (suggestion.address && suggestion.address !== '') {
      // this.setState({query:suggestion.address});
      this.props.handleOnChangeCustomerDetailsState({ query: suggestion.address })
    }

  };

  onSuggestionsClearRequested = () => {
    // this.setState({ suggestions: [] });
    this.props.handleOnChangeCustomerDetailsState({ suggestions: [] })
  };
  btnOnClickPaymentMethodType = (payment_method_type) => {
    this.props.handleOnChangeDeliveryPaymentMethod(payment_method_type)
    // this.setState({selectedDeliveryPaymentMethod:payment_method_type});
  }
  // handleOnChangeDrfDefaultTime = (drp_default_time) =>{
  //     this.setState({drp_default_time}, () => {
  //         console.log('drp_default_time after')
  //         console.log(this.state.drp_default_time)
  //     }); 
  // }

  render() {
    // console.log('this.props')
    // console.log(this.props)
    // console.log('this.props.drp_default_time in render')
    // console.log(this.props.drp_default_time)

    // const now = moment();
    // set the time to 12 pm
    const now = moment().hour(12).minute(0);
    /* console.log('this.props.showFoodAppsCustomerInformationModal')
    console.log(this.props.showFoodAppsCustomerInformationModal)
    if(this.props.showFoodAppsCustomerInformationModal){
        this.handleScriptLoad()
    } */
    // console.log('i a, cus')
    const customerFormData = this.props.customerFormData;
    // console.log('this.props.selectedPhoneNumber')
    // console.log('this.props.selectedPhoneNumber')
    // console.log('this.props.selectedPhoneNumber')
    // console.log(this.props.selectedPhoneNumber)
    let initialValues = {};
    let time_picker_time = null;
    let time_picker_time_obj = {};
    if (Object.keys(customerFormData).length > 0) {
      // console.log('i am in if')
      // console.log('customerFormData.time_picker_time')
      // console.log(customerFormData.time_picker_time)
      initialValues = {
        // timeSelectionRadioBox: customerFormData.timeSelectionRadioBox,
        // notes: customerFormData.notes,
        // drp_default_time: customerFormData.drp_default_time,
        // fullname:customerFormData.fullname,
        // phone_no:customerFormData.phone_no,
        // address:customerFormData.address,
        ...this.props.selectedPhoneNumber
      };
      if (customerFormData.hasOwnProperty("timeSelectionRadioBox") && customerFormData.timeSelectionRadioBox !== undefined) {
        initialValues.timeSelectionRadioBox = customerFormData.timeSelectionRadioBox;
      } else {
        initialValues.timeSelectionRadioBox = 'have_ready_in';
      }
      if (customerFormData.hasOwnProperty("notes") && customerFormData.notes !== undefined) {
        initialValues.notes = customerFormData.notes;
      }
      if (customerFormData.hasOwnProperty("drp_default_time") && customerFormData.drp_default_time !== undefined) {
        initialValues.drp_default_time = customerFormData.drp_default_time;
      }
      if (customerFormData.hasOwnProperty("fullname") && customerFormData.fullname !== undefined) {
        initialValues.fullname = customerFormData.fullname;
      }
      // if (customerFormData.hasOwnProperty("phone_no") && customerFormData.phone_no !== undefined) {
      //   initialValues.phone_no = customerFormData.phone_no;
      // }
      if (customerFormData.hasOwnProperty("address") && customerFormData.address !== undefined) {
        initialValues.address = customerFormData.address;
      }

      if (customerFormData.time_picker_time === '' || customerFormData.time_picker_time === undefined) {
        customerFormData.time_picker_time = now;
        // new line added
        // time_picker_time = now;
        // console.log('i am in now')
      } else {
        // console.log('i am in else of settong')
        // console.log('this.props.time_picker_time')
        // console.log(this.props.time_picker_time)
        // agr to ham ne clock par time set kia to ham wo wala time show krwaiye g jab dobar modal open h g agr ham yeh nai krte to model m clock hamko jo database me order the time hai wohi show krwaye g jo user ke liye confusing h g
        if (this.props.time_picker_time !== '') {
          if (moment.isMoment(this.props.time_picker_time)) {
            time_picker_time = this.props.time_picker_time;
          } else {
            time_picker_time = moment(this.props.time_picker_time);
          }
        } else {
          time_picker_time = moment(customerFormData.time_picker_time);
        }

        // console.log('time_picker_time')
        // console.log('time_picker_time')
        // console.log('time_picker_time')
        // console.log(time_picker_time.toString())
      }

    } else {
      // console.log('i am in else')
      if (Object.keys(this.props.selectedPhoneNumber).length > 0) {
        // console.log('i m in if this.props.selectedPhoneNumber')
        // console.log('i m in if this.props.selectedPhoneNumber')
        // console.log('i m in if this.props.selectedPhoneNumber')
        const selectedPhoneNumber = this.props.selectedPhoneNumber;
        initialValues = { ...this.props.selectedPhoneNumber };
        // initialValues = {timeSelectionRadioBox:'have_ready_in',...this.props.selectedPhoneNumber};
        // initialValues = {timeSelectionRadioBox:'have_ready_in',drp_default_time:'',...this.props.selectedPhoneNumber};
        if (selectedPhoneNumber.hasOwnProperty("fullname") && selectedPhoneNumber.fullname !== undefined) {
          initialValues.fullname = selectedPhoneNumber.fullname;
        } else {
          initialValues.fullname = '';
        }
        // if (selectedPhoneNumber.hasOwnProperty("phone_no") && selectedPhoneNumber.phone_no !== undefined) {
        //   initialValues.phone_no = selectedPhoneNumber.phone_no;
        // } else {
        //   initialValues.phone_no = '';
        // }
        if (selectedPhoneNumber.hasOwnProperty("address") && selectedPhoneNumber.address !== undefined) {
          initialValues.address = selectedPhoneNumber.address;
        } else {
          initialValues.address = '';
        }
        if (this.props.drp_default_time === '') {
          initialValues.drp_default_time = '10';
        } else if (this.props.drp_default_time !== '') {
          initialValues.drp_default_time = this.props.drp_default_time;
        }
        if (this.props.timeSelectionRadioBox === '') {
          initialValues.timeSelectionRadioBox = 'have_ready_in';
        } else if (this.props.timeSelectionRadioBox !== '') {
          initialValues.timeSelectionRadioBox = this.props.timeSelectionRadioBox;
        }
        // console.log('initialValues')
        // console.log('initialValues')
        // console.log(initialValues)
      } else {
        initialValues = { address: '', fullname: '', notes: '', timeSelectionRadioBox:'have_ready_in',drp_default_time:'10' };
        // initialValues = { address: '', fullname: '', phone_no: '', timeSelectionRadioBox: 'have_ready_in', drp_default_time: '10', ...this.props.selectedPhoneNumber };
      }
      // if(this.props.time_picker_time !== )
      // console.log('i am in else this.props.time_picker_time')
      // console.log('i am in else this.props.time_picker_time')
      // console.log(this.props.time_picker_time)
      // console.log(this.props.time_picker_time.toString())
      // yeh new clock value ke liye hai ham yahan check kr rhe hain ke jab modal open h to us ki clock m ham ne already koi time select kia hai ke nai agr ham ne already time set kia hai to ham us time to dobara se clock par show krwa dien g
      if (this.props.time_picker_time !== '') {
        time_picker_time = this.props.time_picker_time;
      } else {
        // agr to ham ne clock par koi time set nai kia to ham current time show krwa dien g clock par
        time_picker_time = now;
      }
      // console.log('i am in else');
    }

    return (
      <Fragment>
        <Script
          key={this.props.name}
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3TiX15TxXzpHOnhV3s66ETx_N62AbxXU&libraries=places"
          attributes={{ id: "someId" }}
        // onLoad={this.handleScriptLoad}
        // onError={this.handleScriptError.bind(this)}
        />
        {/* {   this.props.name_key !== '' ?
                        <ScriptLoader
                            type="text/javascript"
                            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3TiX15TxXzpHOnhV3s66ETx_N62AbxXU&libraries=places"
                            onLoad={() =>{ console.log('loaded google maps!'); console.log('loaded google maps!'); console.log('loaded google maps!'); console.log('loaded google maps!'); console.log('loaded google maps!')}}
                            onError={error => console.error('failed to load google maps:', error.stack)}
                        ></ScriptLoader>
                    : null 
                    }*/}
        {this.props.name_key !== '' ?
          <Script
            key={this.props.name}
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyB3TiX15TxXzpHOnhV3s66ETx_N62AbxXU&libraries=places"
            attributes={{ id: "someId23" }}
          // onLoad={this.handleScriptLoad}
          // onError={this.handleScriptError.bind(this)}
          />
          : null
        }
        <Modal onOpened={this.ModalLoaded} isOpen={this.props.showFoodAppsCustomerInformationModal} toggle={this.toggle} className={this.props.className} size="lg" backdrop="static" keyboard={false}>
          {/* <Modal isOpen={this.props.modal} toggle={this.toggle} className={this.props.className} size="lg"> */}
          <ModalHeader toggle={this.toggle}>Enter Food Apps Customer Information</ModalHeader>
          <ModalBody>
            <FoodAppsCustomerInformationModalBodyForm
              initialValues={initialValues}
              // onSubmit={this.submit}
              onSubmitForm={this.onSubmitForm}
              phoneNumberInput={this.phoneNumberInput}
              // customerDetailForm={this.customerDetailForm}
              toggle={this.toggle}
              inputAddressChangedHandler={this.inputAddressChangedHandler}
              handelOnChangeTimePicker={this.handelOnChangeTimePicker}
              handelOnChangeTimeKeeper={this.handelOnChangeTimeKeeper}
              query={this.props.query}
              isCustomerSavingLoader={this.props.isCustomerSavingLoader}
              onChangeAutoSuggestion={this.onChangeAutoSuggestion}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              suggestions={this.props.suggestions}
              value={this.props.value}
              time_picker_time={time_picker_time}
              time_picker_time_obj={time_picker_time_obj}
              isMistakeActive={this.props.isMistakeActive}
              mistake_type={this.props.mistake_type}
              delivery_type={this.props.delivery_type}
              selectedOrderType={this.props.selectedOrderType}
              existing_order_id={this.props.existing_order_id}
              drp_default_time={this.props.drp_default_time}
              handleOnChangeDrfDefaultTime={this.props.handleOnChangeDrfDefaultTime}
              handleOnChangetimeSelectionRadioBox={this.props.handleOnChangetimeSelectionRadioBox}
              card_processing_fee={this.props.card_processing_fee}
              selectedDeliveryPaymentMethod={this.props.selectedDeliveryPaymentMethod}
              btnOnClickPaymentMethodType={this.btnOnClickPaymentMethodType}
            />
          </ModalBody>

        </Modal>
      </Fragment>
    );
  }
};

export default FoodAppsCustomerInformationModal;