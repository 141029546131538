import React from 'react';
// import {formatExistingOrderDate,formatExistingOrderDateTimeOnly,formatExistingOrderDateTime,isDateMatches} from '../../../utils/general';
import PosKitchenCarouselRenderSelectedDeliveryType from './PosKitchenCarouselRenderSelectedDeliveryType';
import PosKitchenCarouselCartItemBlock from './PosKitchenCarouselCartItemBlock';
import {formatExistingOrderDateTimeOnly,getDifferenceBetweenTwoTimesinHMS, paidReceiptTxt} from '../../../utils/general';
// import Countdown from 'react-countdown-now';
// import RenderDeliveryType from './RenderDeliveryType';
const OrderItemPosKitchenCarousel = (props) => {
    // console.log('props in OrderItemPosKitchenCarousel')
    // console.log(props)
    
    let doneOrderItemStyle = {};
    let notDoneOrderButCashedThroughClass = '';
    let pickUpDelivryTextColor = '';
    if(((props.delivery_type === 'pickup' || props.delivery_type === 'delivery' ) || (props.original_delivery_type === 'pickup' || props.original_delivery_type === 'delivery' )) && (props.kitchen_status === 'done' && (props.status === 'cash' || props.status === 'debit_card' || props.status === 'credit_card' || props.status === 'gift_card'))){
    // if((props.kitchen_status === 'done' && props.status === 'cash')){
        doneOrderItemStyle = {
            backgroundColor: 'green',
        };
        pickUpDelivryTextColor = 'color-white';
    }else if(props.kitchen_status === 'done'){
        doneOrderItemStyle = {
            backgroundColor: 'blue',
        };
        pickUpDelivryTextColor = 'color-white';
    }else if(((props.delivery_type === 'pickup' || props.delivery_type === 'delivery') || (props.original_delivery_type === 'pickup' || props.original_delivery_type === 'delivery' )) && (props.kitchen_status !== 'done' && (props.status === 'cash' || props.status === 'debit_card' || props.status === 'credit_card' || props.status === 'gift_card'))){
    // }else if(props.kitchen_status !== 'done' && props.status === 'cash'){
        
            doneOrderItemStyle = {
                backgroundColor: 'green',
            };
            notDoneOrderButCashedThroughClass = 'notDoneOrderButCashedThroughClass';
            pickUpDelivryTextColor = 'color-white';
        
    }
    // const styles = {
    //     top: "0px",
    //     position: "absolute",
    // };
    return (
        <div className="col-12 no-col-pad" >
            <div className={`food-cart-head kitchen-item-head text-white ${(props.isLate) ? props.isLateClass : ""} ${(props.isMoreThan30Mins) ? props.isMoreThan30MinsClass : ""} ${notDoneOrderButCashedThroughClass} cursor-pointer`}  style={doneOrderItemStyle} onClick={()=> props.onClickHandleKitchenOrder(props)}>

                <div className={`food-info-head`} >
                    <div className="row">
                        <div className="col-6">
                            <PosKitchenCarouselRenderSelectedDeliveryType 
                                selectedOrderType={props.delivery_type}
                                pickUpDelivryTextColor={pickUpDelivryTextColor}
                            />
                        </div>
                        <div className="col-6 text-right">
                            {/* <p>3:30</p> */}
                            
                            {/* <Countdown date={new Date(props.order_completion_datetime).getTime()} /> */}
                            {/* {props.order_completion_datetime} */}
                            {/* {new Date(props.order_completion_datetime).getTime()} */}
                            {/* <Countdown date={new Date(props.order_completion_datetime)} /> */}
                            {
                                (props.delivery_type === 'dine_in' || props.delivery_type === 'takeout')?
                                    <p>{getDifferenceBetweenTwoTimesinHMS(props.order_completion_datetime)}</p>

                                :
                                    <p>{formatExistingOrderDateTimeOnly(props.order_completion_datetime)}</p>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {
                                (props.objCustomer !== undefined && props.objCustomer !== null) && Object.keys(props.objCustomer).length > 0 ?
                                    <p className="CustomerName">{props.objCustomer.fullname}</p>
                                :<p></p>
                            }
                        </div>
                        <div className="col text-right">
                            <p className="ticket_no fs-10">Ticket# {props.ticket_no} {props.created_from}</p>
                        </div>
                    </div>
                    {
                        ((props.created_from === 'web' || props.created_from === 'pos') && Object.keys(props.objCustomer).length > 0 && (props.objCustomer.notes && props.objCustomer.notes !== '')) ?
                            <div className="row">
                                <div className="col">
                                    <p className="ticket_no fs-10">{props.objCustomer.notes}</p>
                                </div>
                            </div>
                        :null
                    }
                </div>
            </div>
            
            <div className="food-cart-head kitchen-item-body text-white cursor-pointer " onClick={()=> props.onClickHandleKitchenOrder(props)}>
                {
                    ((props.created_from === 'tablet' || props.created_from === 'web') && paidReceiptTxt.includes(props.payment_method) === false ) ?
                        <div><p className="font-weight-bolder text-danger text-capitalize">Not Paid</p></div>
                    :null
                }
                {
                    ((props.created_from === 'tablet' || props.created_from === 'web') && paidReceiptTxt.includes(props.payment_method)) ?
                        <div><p className="font-weight-bolder text-success text-capitalize">Paid</p></div>
                    :null
                }
                {/* <p>{props.payment_method}</p> */}
                <PosKitchenCarouselCartItemBlock
                    products={props.order_details}
                    comboItemColor={props.comboItemColor}
                    type="cart"
                />
                
            </div>

        </div>
    );
}



export default OrderItemPosKitchenCarousel;